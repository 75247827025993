export default {
  intro: () => import("/vercel/path0/meedoen/layouts/intro.vue"),
  meedoen: () => import("/vercel/path0/meedoen/layouts/meedoen.vue"),
  default: () => import("/vercel/path0/.app/layouts/default.vue"),
  empty: () => import("/vercel/path0/.app/layouts/empty.vue"),
  public: () => import("/vercel/path0/.app/layouts/public.vue"),
  wide: () => import("/vercel/path0/.app/layouts/wide.vue"),
  sidebar: () => import("/vercel/path0/layers/tairo-layout-sidebar/layouts/sidebar.vue"),
  collapse: () => import("/vercel/path0/layers/tairo-layout-collapse/layouts/collapse.vue"),
  topnav: () => import("/vercel/path0/layers/tairo-layout-topnav/layouts/topnav.vue")
}